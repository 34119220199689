import React from "react";
import BaseApp from "../../BaseComponent/baseApp";
import {
  AboutSection,
  AboutUsPageTitle,
  HistorySection,
  HowItWorksSection,
  ProcessSection,
  ScientistSection,
  TestimonialsSection,
} from "../common/About-Us";
import HelmetPage from "./Helmet";

export default function AboutUs() {
  return (
    <>
      <HelmetPage
        title={
          "SpaceLakh Tech Hub - Stay Updated on the Latest Tech Trends"
        }
        content={
          "Dive into the world of technology with SpaceLakh. Read insightful articles on gadgets, software, and emerging trends. Stay ahead in the tech game!"
        }
      />
      <BaseApp>
        <AboutUsPageTitle />
        <AboutSection />
        {/* <HistorySection /> */}
        {/* <HowItWorksSection /> */}
        {/* <ProcessSection /> */}
        {/* <ScientistSection /> */}
        {/* <TestimonialsSection /> */}
      </BaseApp>
    </>
  );
}
