import React from "react";

function ServicesArea() {
  return (
    <section className="services-area pt-100 pb-70 bg-f1f8fb">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={require("../../assets/img/star-icon.png")} alt="image" />
            Our Features
          </span>
          <h2>We’re Here To Help</h2>
          <p>
            Providing comprehensive support and assistance to ensure your
            success and satisfaction.
          </p>
        </div>
        <div className="row">
          <ServiceItem
            iconSrc={require("../../assets/img/services/service-icon1.png")}
            title="Incredible Infrastructure"
            description="Experience an unparalleled infrastructure designed to meet your needs and exceed expectations."
          />
          <ServiceItem
            iconSrc={require("../../assets/img/services/service-icon2.png")}
            title="Email Notifications"
            description="Stay informed and connected with our seamless email notification system, keeping you updated and engaged."
          />
          <ServiceItem
            iconSrc={require("../../assets/img/services/service-icon3.png")}
            title="Simple Dashboard"
            description="Access a user-friendly and intuitive dashboard, simplifying your experience and enhancing usability."
          />
          <ServiceItem
            iconSrc={require("../../assets/img/services/service-icon4.png")}
            title="Information Retrieval"
            description="Effortlessly retrieve and manage crucial information with our advanced and efficient system."
          />
          <ServiceItem
            iconSrc={require("../../assets/img/services/service-icon5.png")}
            title="Drag and Drop"
            description="Enhance your workflow with the convenience of drag-and-drop functionality for easy and efficient operations."
          />
          <ServiceItem
            iconSrc={require("../../assets/img/services/service-icon6.png")}
            title="Deadline Reminders"
            description="Never miss a deadline with our timely reminders, ensuring you stay on track and organized."
          />
        </div>
      </div>
    </section>
  );
}

function ServiceItem({ iconSrc, title, description }) {
  return (
    <div className="col-lg-4 col-sm-6">
      <div className="single-services-item-box">
        <div className="icon">
          <img src={iconSrc} alt="image" />
        </div>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}

export default ServicesArea;
