import React from "react";
import { Link, useNavigate } from "react-router-dom";
import shape1 from "../../assets/img/shape/shape1.svg";

export const AboutUsPageTitle = () => {
  const navigate = useNavigate();
  return (
    <div className="page-title-area">
      <div className="container">
        <div className="page-title-content">
          <h2>About Us</h2>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>About Us</li>
          </ul>
        </div>
      </div>
      <div className="shape-img1">
        <img src={shape1} alt="image" />
      </div>
      <div className="shape-img2">
        <img src={require("../../assets/img/shape/shape2.png")} alt="image" />
      </div>
      <div className="shape-img3">
        <img src={require("../../assets/img/shape/shape3.png")} alt="image" />
      </div>
    </div>
  );
};

export const AboutSection = () => {
  return (
    <section className="about-area ptb-100">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-image">
              <img
                src={require("../../assets/img/about/about-img5.png")}
                alt="image"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                <span className="sub-title">
                  <img
                    src={require("../../assets/img/star-icon.png")}
                    alt="image"
                  />
                  About Us
                </span>
                <h2> Designing Digital Success, One Click at a Time.</h2>
                <p>
                  At SpaceLakh, we blend creativity with strategy to craft
                  immersive digital experiences. Beyond ordinary web designing
                  and digital marketing, we are your dedicated partners in
                  navigating the realms of digital success.
                </p>
                <ul className="features-list">
                  <li>
                    <img
                      src={require("../../assets/img/icons/icon4.png")}
                      alt="image"
                    />
                    <h3>1+ Years</h3>
                    <p>On the market</p>
                  </li>
                  <li>
                    <img
                      src={require("../../assets/img/icons/icon5.png")}
                      alt="image"
                    />
                    <h3>20+</h3>
                    <p>Team members</p>
                  </li>
                  <li>
                    <img
                      src={require("../../assets/img/icons/icon6.png")}
                      alt="image"
                    />
                    <h3>100%</h3>
                    <p>Satisfaction rate</p>
                  </li>
                  <li>
                    <img
                      src={require("../../assets/img/icons/icon7.png")}
                      alt="image"
                    />
                    <h3>80%</h3>
                    <p>Senior scientist</p>
                  </li>
                </ul>
                <p>
                  we don't just build websites and campaigns — we craft digital
                  triumphs. Welcome to a journey where your digital dreams find
                  their extraordinary home.
                </p>
                <Link className="default-btn" to="/">
                  <i className="flaticon-right"></i>More About Us
                  <span></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="circle-shape1">
        <img
          src={require("../../assets/img/shape/circle-shape1.png")}
          alt="image"
        />
      </div>

      <div className="container">
        <div className="about-inner-area">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="about-text">
                <h3>Our History</h3>
                <p>
                  At the core of successful communication lies real innovations
                  and a positive customer experience.
                </p>
                <ul className="features-list">
                  <li>
                    <i className="flaticon-tick"></i> Activate Listening
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> Brilliant minds
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> Better. Best. Wow!
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> Branding it better!
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="about-text">
                <h3>Our Mission</h3>
                <p>
                  Our mission revolves around real innovations and fostering
                  positive customer experiences.
                </p>
                <ul className="features-list">
                  <li>
                    <i className="flaticon-tick"></i> Creating. Results.
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> Expect more
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> Good thinking
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> In real we trust
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
              <div className="about-text">
                <h3>Who we are</h3>
                <p>
                  Discover the essence of successful communication through real
                  innovations and positive customer experiences.
                </p>
                <ul className="features-list">
                  <li>
                    <i className="flaticon-tick"></i> Stay real. Always.
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> We have you covered
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> We turn heads
                  </li>
                  <li>
                    <i className="flaticon-tick"></i> Your brand, promoted
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="circle-shape1">
        <img
          src={require("../../assets/img/shape/circle-shape1.png")}
          alt="image"
        />
      </div>
    </section>
  );
};

export const HistorySection = () => {
  return (
    <section className="history-area ptb-100 bg-fafafb">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={require("../../assets/img/star-icon.png")} alt="image" />
            Our History
          </span>
          <h2>History Begins in 2010</h2>
        </div>
        <ol className="timeline history-timeline">
          <li className="timeline-block">
            <div className="timeline-date">
              <span>2010</span>February 20<sup>th</sup>
            </div>
            <div className="timeline-icon">
              <span className="dot-badge"></span>
            </div>
            <div className="timeline-content">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-12">
                  <div className="content">
                    <h3>Founded</h3>
                    <p>
                      Real innovations and a positive customer experience are
                      the heart of successful communication. Lorem ipsum dolor
                      sit amet, sectetur adipiscing elit, tempor incididunt ut
                      labore et dolore magna.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12">
                  <div className="image">
                    <img
                      src={require("../../assets/img/history/history1.jpg")}
                      alt="image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="timeline-block">
            <div className="timeline-date">
              <span>2013</span>January 14<sup>th</sup>
            </div>
            <div className="timeline-icon">
              <span className="dot-badge"></span>
            </div>
            <div className="timeline-content">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-12">
                  <div className="content">
                    <h3>Global Success</h3>
                    <p>
                      Real innovations and a positive customer experience are
                      the heart of successful communication. Lorem ipsum dolor
                      sit amet, sectetur adipiscing elit, tempor incididunt ut
                      labore et dolore magna.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12">
                  <div className="image">
                    <img
                      src={require("../../assets/img/history/history2.jpg")}
                      alt="image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="timeline-block">
            <div className="timeline-date">
              <span>2016</span>March 25<sup>th</sup>
            </div>
            <div className="timeline-icon">
              <span className="dot-badge"></span>
            </div>
            <div className="timeline-content">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-12">
                  <div className="content">
                    <h3>Founded Data Center</h3>
                    <p>
                      Real innovations and a positive customer experience are
                      the heart of successful communication. Lorem ipsum dolor
                      sit amet, sectetur adipiscing elit, tempor incididunt ut
                      labore et dolore magna.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12">
                  <div className="image">
                    <img
                      src={require("../../assets/img/history/history3.jpg")}
                      alt="image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="timeline-block">
            <div className="timeline-date">
              <span>2020</span>December 10<sup>th</sup>
            </div>
            <div className="timeline-icon">
              <span className="dot-badge"></span>
            </div>
            <div className="timeline-content">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-12">
                  <div className="content">
                    <h3>International Award</h3>
                    <p>
                      Real innovations and a positive customer experience are
                      the heart of successful communication. Lorem ipsum dolor
                      sit amet, sectetur adipiscing elit, tempor incididunt ut
                      labore et dolore magna.
                    </p>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12">
                  <div className="image">
                    <img
                      src={require("../../assets/img/history/history4.jpg")}
                      alt="image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </section>
  );
};

export const HowItWorksSection = () => {
  return (
    <section className="how-its-work-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-content">
              <span className="sub-title">
                <img
                  src={require("../../assets/img/star-icon.png")}
                  alt="image"
                />
                People Love Us
              </span>
              <h2>Why Choose Us?</h2>
              <p>
                At SpaceLakh, we stand out in the dynamic world of digital
                marketing for several compelling reasons:
              </p>
              <div className="inner-box">
                <div className="single-item">
                  <div className="count-box">1</div>
                  <h3>Proven Track Record</h3>
                  <p>
                    Our extensive experience speaks volumes. Over the years, we
                    have successfully executed digital marketing campaigns for a
                    diverse range of clients, achieving measurable results and
                    driving tangible growth.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">2</div>
                  <h3>Tailored Strategies</h3>
                  <p>
                    We understand that one size doesn't fit all. Our team of
                    experts takes the time to understand your business,
                    industry, and goals, crafting personalized digital marketing
                    strategies that align with your unique needs.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">3</div>
                  <h3>Cutting-Edge Technology</h3>
                  <p>
                    We stay ahead of the curve by leveraging the latest
                    technologies and tools in the digital marketing landscape.
                    This commitment to innovation ensures that our clients
                    benefit from the most effective and up-to-date solutions.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">4</div>
                  <h3>Data-Driven Decision Making</h3>
                  <p>
                    Our approach is grounded in data and analytics. We don't
                    just implement strategies; we constantly analyze performance
                    metrics, refine our approaches, and provide transparent
                    reports, allowing you to see the impact of our efforts.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">5</div>
                  <h3>Dedicated Team of Experts</h3>
                  <p>
                    Your success is our priority. Our team comprises skilled
                    professionals with diverse expertise in SEO, social media,
                    content creation, and more. Together, we collaborate to
                    deliver comprehensive and integrated digital marketing
                    solutions.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">6</div>
                  <h3>Client-Centric Approach</h3>
                  <p>
                    We believe in building long-lasting relationships. Our
                    client-centric approach means that we are committed to
                    understanding your evolving needs and adapting our
                    strategies to ensure ongoing success in a rapidly changing
                    digital landscape.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">7</div>
                  <h3>Customizable Services</h3>
                  <p>
                    Flexibility is key. Whether you're a small startup or a
                    large enterprise, our services are scalable and customizable
                    to accommodate your growth and evolving requirements.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">8</div>
                  <h3>Transparent Communication</h3>
                  <p>
                    We value clear and open communication. Throughout our
                    partnership, you can expect regular updates, progress
                    reports, and a dedicated point of contact to address any
                    questions or concerns.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-image">
              <img
                src={require("../../assets/img/how-its-work.png")}
                alt="image"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const ProcessSection = () => {
  return (
    <section className="process-area pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={require("../../assets/img/star-icon.png")} alt="image" />
            How It's Work
          </span>
          <h2>The Data Science Process</h2>
          <p>
            The data science process involves several key steps to extract
            valuable insights and knowledge from data. Here's an overview of the
            typical stages in the data science process
          </p>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="single-process-box">
              <div className="number">1</div>
              <div className="image">
                <img
                  src={require("../../assets/img/process/process1.png")}
                  alt="image"
                />
              </div>
              <h3>Problem Definition</h3>
              <p>
                Identify and define the problem or objective that data science
                can help address. Clearly understand the business goals and how
                data can contribute to achieving them.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-process-box">
              <div className="number">2</div>
              <div className="image">
                <img
                  src={require("../../assets/img/process/process2.png")}
                  alt="image"
                />
              </div>
              <h3>Collect the Raw Data</h3>
              <p>
                Gather relevant data from various sources, such as databases,
                APIs, files, or external datasets. Ensure data quality,
                completeness, and accuracy.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-process-box">
              <div className="number">3</div>
              <div className="image">
                <img
                  src={require("../../assets/img/process/process3.png")}
                  alt="image"
                />
              </div>
              <h3>Data Cleaning and Preprocessing</h3>
              <p>
                Handle missing values, outliers, and errors in the data.
                Transform and preprocess data to make it suitable for analysis,
                including normalization and standardization.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-process-box">
              <div className="number">4</div>
              <div className="image">
                <img
                  src={require("../../assets/img/process/process4.png")}
                  alt="image"
                />
              </div>
              <h3>Exploratory Data Analysis (EDA)</h3>
              <p>
                Explore the dataset using statistical and visual methods to
                understand its characteristics. Identify patterns, trends, and
                potential relationships between variables.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-process-box">
              <div className="number">5</div>
              <div className="image">
                <img
                  src={require("../../assets/img/process/process5.png")}
                  alt="image"
                />
              </div>
              <h3>Feature Engineering</h3>
              <p>
                Create new features or transform existing ones to enhance the
                predictive power of the model. Select relevant features that
                contribute most to the model's performance.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="single-process-box">
              <div className="number">6</div>
              <div className="image">
                <img
                  src={require("../../assets/img/process/process6.png")}
                  alt="image"
                />
              </div>
              <h3>Model Selection</h3>
              <p>
                Choose the appropriate machine learning or statistical model
                based on the nature of the problem and data. Consider factors
                such as model complexity, interpretability, and performance
                metrics.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="circle-shape1">
        <img
          src={require("../../assets/img/shape/circle-shape1.png")}
          alt="image"
        />
      </div>
    </section>
  );
};

export const ScientistSection = () => {
  return (
    <section className="scientist-area bg-color pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={require("../../assets/img/star-icon.png")} alt="image" />
            Team Members
          </span>
          <h2>Our Data Scientist</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna.
          </p>
        </div>
        <div className="row">
          <div className="col-lg-3 col-sm-6 col-md-6">
            <div className="single-scientist-box">
              <div className="image">
                <img
                  src={require("../../assets/img/scientist/scientist1.png")}
                  alt="image"
                />
              </div>
              <div className="content">
                <h3>Merv Adrian</h3>
                <span>Data Management</span>
                <ul className="social">
                  <li>
                    <Link
                      to="https://www.facebook.com/profile.php?id=61555235480070&sfnsn=wiwspwa&mibextid=vk8aRt"
                      className="d-block"
                      target="_blank"
                    >
                      <i className="bx bxl-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.twitter.com/"
                      className="d-block"
                      target="_blank"
                    >
                      <i className="bx bxl-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/spacelakh?igsh=ODl0cGdjOTlveno5"
                      className="d-block"
                      target="_blank"
                    >
                      <i className="bx bxl-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/"
                      className="d-block"
                      target="_blank"
                    >
                      <i className="bx bxl-linkedin"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Repeat the structure for other scientists */}
        </div>
      </div>
    </section>
  );
};

export const TestimonialsSection = () => {
  return (
    <>
      <section className="testimonials-area bg-f1f8fb">
        <div className="container">
          <div className="section-title">
            <span className="sub-title">
              <img
                src={require("../../assets/img/star-icon.png")}
                alt="image"
              />
              Testimonials
            </span>
            <h2>What Our Clients are Saying?</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna.
            </p>
          </div>
          <div className="testimonials-view-btn text-center">
            <Link className="default-btn" to="/">
              <i className="flaticon-view"></i>Check Out All Reviews
              <span></span>
            </Link>
          </div>
        </div>
        <div className="shape-img1">
          <img src={require("../../assets/img/shape/shape1.svg")} alt="image" />
        </div>
      </section>

      <div className="partner-area pt-100 pb-70 bg-f1f8fb">
        <div className="container">
          <div className="row align-items-center">
            {/* Repeat the structure for each partner */}
            <div className="col-lg-2 col-6 col-sm-4 col-md-4">
              <div className="single-partner-item animate__animated animate__fadeInUp animate__delay-0.1s">
                <img
                  src={require("../../assets/img/partner/partner1.png")}
                  alt="image"
                />
              </div>
            </div>
            <div className="col-lg-2 col-6 col-sm-4 col-md-4">
              <div className="single-partner-item animate__animated animate__fadeInUp animate__delay-0.2s">
                <img
                  src={require("../../assets/img/partner/partner2.png")}
                  alt="image"
                />
              </div>
            </div>
            <div className="col-lg-2 col-6 col-sm-4 col-md-4">
              <div className="single-partner-item animate__animated animate__fadeInUp animate__delay-0.3s">
                <img
                  src={require("../../assets/img/partner/partner3.png")}
                  alt="image"
                />
              </div>
            </div>
            <div className="col-lg-2 col-6 col-sm-4 col-md-4">
              <div className="single-partner-item animate__animated animate__fadeInUp animate__delay-0.4s">
                <img
                  src={require("../../assets/img/partner/partner4.png")}
                  alt="image"
                />
              </div>
            </div>
            <div className="col-lg-2 col-6 col-sm-4 col-md-4">
              <div className="single-partner-item animate__animated animate__fadeInUp animate__delay-0.5s">
                <img
                  src={require("../../assets/img/partner/partner5.png")}
                  alt="image"
                />
              </div>
            </div>
            <div className="col-lg-2 col-6 col-sm-4 col-md-4">
              <div className="single-partner-item animate__animated animate__fadeInUp animate__delay-0.6s">
                <img
                  src={require("../../assets/img/partner/partner6.png")}
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
