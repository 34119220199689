import React from "react";
import { Link } from "react-router-dom";

function ProjectStartArea() {
  return (
    <div className="project-start-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="project-start-image">
              <img
                src={require("../../assets/img/project-start1.png")}
                alt="image"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2>We Like to Start Your Project With Us</h2>
              <p>
                Someone's sitting in the shade today because someone planted a
                tree a long time ago.
              </p>
              <Link className="default-btn" to="/contact">
                <i className="flaticon-web"></i>Get Started
                <span></span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="circle-shape1">
        <img
          src={require("../../assets/img/shape/circle-shape1.png")}
          alt="image"
        />
      </div>
    </div>
  );
}

export default ProjectStartArea;
