import React, { useState } from "react";
import BaseApp from "../../BaseComponent/baseApp";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import "../bookMeeting.css";
import emailjs from "emailjs-com";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import HelmetPage from "./Helmet";

export const BookMeeting = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const durationOptions = [
    { value: 15, label: "15 min" },
    { value: 30, label: "30 min" },
    { value: 60, label: "1 hour" },
  ];

  const timeOptions = [
    { value: "09:00", label: "9:00 AM" },
    { value: "10:00", label: "10:00 AM" },
    { value: "11:00", label: "11:00 AM" },
    { value: "12:00", label: "12:00 PM" },
    { value: "13:00", label: "1:00 PM" },
    { value: "14:00", label: "2:00 PM" },
    { value: "15:00", label: "3:00 PM" },
    { value: "16:00", label: "4:00 PM" },
    { value: "17:00", label: "5:00 PM" },
  ];

  const validateForm = () => {
    const newErrors = {};
    if (!selectedDate) newErrors.date = "Please select a date.";
    if (!selectedDuration) newErrors.duration = "Please select a duration.";
    if (!selectedTime) newErrors.time = "Please select a time.";
    if (!name) newErrors.name = "Please enter your name.";
    if (!email) newErrors.email = "Please enter your email.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleDateChange = (date) => {
    const currentDate = new Date();
    if (date >= currentDate) {
      setSelectedDate(date);
    }
  };

  const handleDurationChange = (duration) => {
    setSelectedDuration(duration);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleScheduleMeeting = async () => {
    if (validateForm()) {
      // Create a form element
      const form = document.createElement("form");
      form.style.display = "none"; // Hide the form

      // Add form fields
      const dateInput = document.createElement("input");
      dateInput.name = "date";
      dateInput.value = selectedDate;
      form.appendChild(dateInput);

      const durationInput = document.createElement("input");
      durationInput.name = "duration";
      durationInput.value = selectedDuration.label;
      form.appendChild(durationInput);

      const timeInput = document.createElement("input");
      timeInput.name = "time";
      timeInput.value = selectedTime.label;
      form.appendChild(timeInput);

      const nameInput = document.createElement("input");
      nameInput.name = "name";
      nameInput.value = name;
      form.appendChild(nameInput);

      const emailInput = document.createElement("input");
      emailInput.name = "email";
      emailInput.value = email;
      form.appendChild(emailInput);

      // Append the form to the document body
      document.body.appendChild(form);

      try {
        // Use the form element as the third parameter
        await emailjs.sendForm(
          "service_9iyz64h",
          "template_mbnhimw",
          form,
          "N5qphGjI56cFAVhdK"
        );
        message.success("Meeting Scheduled");
        // Add any success logic or redirect here
      } catch (error) {
        console.log(error.text);
      } finally {
        // Remove the form from the document body
        document.body.removeChild(form);
      }
    } else {
      console.log("Form validation failed.");
    }
    navigate("/");
  };

  return (
    <>
      <HelmetPage
        title={
          "Boost Your Brand on Instagram - Proven Marketing Strategies"
        }
        content={
          "Dive into the dynamic world of digital marketing. Explore strategies, trends, and tools to elevate your online presence with SpaceLakh. | Discover effective Instagram marketing techniques with SpaceLakh. Elevate your brand, engage your audience, and stay ahead in the competitive digital landscape. | Maximize your reach and conversions with expert Facebook advertising insights. Learn essential tips and tricks on SpaceLakh for a winning ad strategy. | Understand the metrics that matter. SpaceLakh's guide to social media analytics empowers you to measure and optimize your digital marketing efforts. | Elevate your content marketing game with SpaceLakh's expert tips. Learn to create engaging, shareable content that resonates with your target audience. | Embark on your SEO journey with SpaceLakh's beginner-friendly guide. Learn the essentials to enhance your website's visibility and drive organic traffic. | Optimize your email campaigns with SpaceLakh's best practices. From subject lines to segmentation, discover the keys to effective email marketing. | Harness the power of video marketing. Explore creative strategies and tips to captivate your audience and boost brand engagement."
        }
      />
      <BaseApp>
        <div className="calendar-page">
          <h1>Schedule a Meeting</h1>
          <div className="form-container">
            <div className="form-group">
              <label>Date:</label>
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                minDate={new Date()}
                placeholderText="Select a date"
              />
              {errors.date && (
                <span style={{ color: "crimson" }} className="error">
                  {errors.date}
                </span>
              )}
            </div>
            <div className="form-group">
              <label>How long do you need?</label>
              <Select
                options={durationOptions}
                value={selectedDuration}
                onChange={handleDurationChange}
                placeholder="Select duration"
              />
              {errors.duration && (
                <span style={{ color: "crimson" }} className="error">
                  {errors.duration}
                </span>
              )}
            </div>
            <div className="form-group">
              <label>What time works best?</label>
              <Select
                options={timeOptions}
                value={selectedTime}
                onChange={handleTimeChange}
                placeholder="Select time"
              />
              {errors.time && (
                <span style={{ color: "crimson" }} className="error">
                  {errors.time}
                </span>
              )}
            </div>
            <div className="form-group">
              <label>Name:</label>
              <input type="text" value={name} onChange={handleNameChange} />
              {errors.name && (
                <span style={{ color: "crimson" }} className="error">
                  {errors.name}
                </span>
              )}
            </div>
            <div className="form-group">
              <label>Email:</label>
              <input type="email" value={email} onChange={handleEmailChange} />
              {errors.email && (
                <span style={{ color: "crimson" }} className="error">
                  {errors.email}
                </span>
              )}
            </div>
            <button onClick={handleScheduleMeeting}>Schedule Meeting</button>
          </div>
        </div>
      </BaseApp>
    </>
  );
};
