import React from "react";
import { Helmet } from "react-helmet";

const HelmetPage = ({ title, content }) => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={content} />
      </Helmet>
      {/* Your page content goes here */}
    </div>
  );
};

export default HelmetPage;
