import React, { useState, useEffect } from "react";

const FlightSearch = () => {
  const [flightOffers, setFlightOffers] = useState([]);
  console.log("flightOffers: ", flightOffers);
  const apiEndpoint = "https://test.api.amadeus.com/v2/shopping/flight-offers";
  const apiKey = "iAH0X9w9gxsvjPeojCJgMtCFDVsL"; // Replace with your actual Amadeus API key

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiEndpoint}?originLocationCode=SYD&destinationLocationCode=BKK&departureDate=2024-01-02&adults=1&nonStop=false&max=250`,
          {
            headers: {
              Authorization: `Bearer ${apiKey}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setFlightOffers(data.data); // Assuming the relevant flight offers data is under the 'data' property
      } catch (error) {
        console.error("Error fetching flight offers:", error);
      }
    };

    fetchData();
  }, [apiEndpoint, apiKey]);

  return (
    <div>
      <h1>Flight Offers</h1>
      <ul>
        {flightOffers.map((offer) => (
          <li key={offer.id}>
            {/* Render relevant flight offer information */}
            {offer?.price?.total}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FlightSearch;
