import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./ContactPage.css";
import image1 from "../assets/shape/shape1.svg";
import image2 from "../assets/shape/shape2.png";
import image3 from "../assets/shape/shape3.png";
import image4 from "../assets/shape/contact.png";
import image5 from "../assets/shape/star-icon.png";
import "boxicons/css/boxicons.min.css";
// import { TiTick } from "react-icons/ti";
import emailjs from "emailjs-com";
import BaseApp from "../BaseComponent/baseApp";
import { Link, useNavigate } from "react-router-dom";
import HelmetPage from "./Routes/Helmet";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    subject: "",
    message: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here (e.g., send data to the server, update state, etc.)
    console.log("Form submitted:", formData);

    // Send email using emailjs
    emailjs
      .sendForm(
        "service_9iyz64h",
        "template_mbnhimw",
        e.target,
        "N5qphGjI56cFAVhdK"
      )
      .then((result) => {
        console.log(result.text);
        // Add any success logic or redirect here
      })
      .catch((error) => {
        console.log(error.text);
      });

    // Clear the form after submission if needed
    setFormData({
      name: "",
      email: "",
      number: "",
      subject: "",
      message: "",
    });
    navigate("/");
  };

  return (
    <>
      <HelmetPage
        title={"Email Marketing Mastery - Best Practices for Success"}
        content={
          "Optimize your email campaigns with SpaceLakh's best practices. From subject lines to segmentation, discover the keys to effective email marketing."
        }
      />
      <BaseApp>
        <Container>
          <Row className="page-title-area">
            <Col>
              <div className="page-title-content">
                <h2>Contact</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Contact</li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div
                className="shape-img1"
                style={{ marginLeft: "-70px", marginTop: "250px" }}
              >
                <img src={image1} alt="image" />
              </div>
              <div className="shape-img2" style={{ marginBottom: "300px" }}>
                <img src={image2} alt="image" />
              </div>
              <div className="shape-img3">
                <img src={image3} alt="image" />
              </div>
            </Col>
          </Row>
        </Container>

        <div className="contact-info-area pt-100 pb-70">
          <Container>
            <Row>
              <Col lg={4} md={6}>
                <div className="contact-info-box">
                  <div className="back-icon">
                    <i className="bx bx-map"></i>
                  </div>
                  <div className="icon">
                    <i className="bx bx-map"></i>
                  </div>
                  <h3>Our Address</h3>
                  <p>
                    No.231 - 1, Sathya Garden, Phase-2 Uthamasolapuram, Salem
                    TamilNadu -636010 ,India
                  </p>
                </div>
              </Col>
              <Col lg={4} md={6}>
                <div className="contact-info-box">
                  <div className="back-icon">
                    <i className="bx bx-phone-call"></i>
                  </div>
                  <div className="icon">
                    <i className="bx bx-phone-call"></i>
                  </div>
                  <h3>Contact</h3>
                  <p>
                    Mobile: <Link to="tel:91 8778161679">+91 8778161679</Link>
                  </p>
                  <p>
                    E-mail:{" "}
                    <Link to="https://mail.google.com/mail/u/0/?hl=en-GB&tf=cm&fs=1&to=contact@spacelakh.com">
                      <span
                        className="__cf_email__"
                        data-cfemail="0a626f6666654a7e6b786424696567"
                      >
                        contact@spacelakh.com
                      </span>
                    </Link>
                  </p>
                </div>
              </Col>
              <Col lg={4} md={6} className="offset-lg-0 offset-md-3">
                <div className="contact-info-box">
                  <div className="back-icon">
                    <i className="bx bx-time-five"></i>
                  </div>
                  <div className="icon">
                    <i className="bx bx-time-five"></i>
                  </div>
                  <h3>Hours of Operation</h3>
                  <p>Monday - Friday: 10:00 Am - 06:00 Pm</p>
                  <p>Sunday &amp; Saturday: 10:00 Am - 06:00 Pm</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <section className="contact-area pb-100">
          <Container>
            <Row>
              <Col>
                <div className="section-title">
                  <span className="sub-title">
                    <img src={image5} alt="image" />
                    Get in Touch
                  </span>
                  <h2>Ready to Get Started?</h2>
                  <p>
                    Your email address will not be published. Required fields
                    are marked *
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col lg={6} md={12}>
                <div className="contact-image">
                  <img src={image4} alt="image" />
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className="contact-form">
                  <Form onSubmit={handleSubmit}>
                    <Row style={{ gap: "20px" }}>
                      <Col lg={12} md={6}>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="name"
                            placeholder="Name"
                            required
                            value={formData.name}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={6}>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="email"
                            placeholder="Email"
                            required
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={6}>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="number"
                            placeholder="Phone number"
                            required
                            value={formData.number}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={6}>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            required
                            value={formData.subject}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={12} md={12}>
                        <Form.Group>
                          <Form.Control
                            as="textarea"
                            name="message"
                            rows={6}
                            placeholder="Write your message..."
                            required
                            value={formData.message}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={12} sm={12}>
                        <Button type="submit" className="default-btn">
                          {/* <TiTick
                          style={{ marginLeft: "-30px", fontSize: "23px" }}
                        />{" "} */}
                          Send Message
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </BaseApp>
    </>
  );
};

export default ContactPage;
