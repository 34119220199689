// FloatingIcon.js
import React from "react";
import { Button, Space } from "antd";
import {
  MessageOutlined,
  WhatsAppOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

const FloatingIcon = () => {
  const handleWhatsAppClick = () => {
    const phoneNumber = "+919962188990";
    const whatsappLink = `https://wa.me/${phoneNumber}`;
    window.open(whatsappLink, "_blank");
  };

  const handlePhoneClick = () => {
    const phoneNumber = "+91 8778161679";

    const phoneLink = `tel:${phoneNumber}`;

    window.location.href = phoneLink;
  };

  return (
    <div
      style={{
        position: "fixed",
        right: 10,
        top: "50%",
        transform: "translateY(-50%)",
        zIndex: 1000,
      }}
    >
      <Space direction="vertical" size={16}>
        <Button
          style={{ background: "#FF5D22", color: "white" }}
          icon={<MessageOutlined />}
          // onClick={handleEmailClick}
          href="https://mail.google.com/mail/u/0/?hl=en-GB&tf=cm&fs=1&to=contact@spacelakh.com"
          target="_blank"
        ></Button>
        <Button
          style={{ background: "#FF5D22", color: "white" }}
          icon={<WhatsAppOutlined />}
          onClick={handleWhatsAppClick}
        ></Button>
        <Button
          style={{ background: "#FF5D22", color: "white" }}
          icon={<PhoneOutlined />}
          onClick={handlePhoneClick}
        ></Button>
      </Space>
    </div>
  );
};

export default FloatingIcon;
