import React from "react";
import figma from "../../assets/img/tools/figma-5.svg";
import vscode from "../../assets/img/tools/visual-studio-code-1.svg";

function PartnerArea() {
  const partnerLogos = [
    `${figma}`,
    `${vscode}`,
    `${require("../../assets/img/tools/ai.png")}`,
    `${require("../../assets/img/tools/canva.png")}`,
    `${require("../../assets/img/tools/pr.png")}`,
    `${require("../../assets/img/tools/ps.png")}`,
  ];

  return (
    <div className="partner-area pt-100 pb-70 bg-f1f8fb">
      <div
        className="container"
        style={{ gap: "35px", display: "flex", flexDirection: "column" }}
      >
        <h1>Tools We Use</h1>
        <div className="row align-items-center justify-content-center">
          {partnerLogos.map((logo, index) => (
            <div
              key={index}
              className={`col-lg-2 col-6 col-sm-4 col-md-4 single-partner-item animate__animated animate__fadeInUp animate__delay-${
                index * 0.1
              }s`}
            >
              <img src={logo} alt={`Partner ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PartnerArea;
