import React from "react";

function OurMissionArea() {
  return (
    <div className="our-mission-area pb-100">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <OurMissionContent />
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="our-mission-image">
              <img
                src={require("../../assets/img/services/service2.png")}
                alt="image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function OurMissionContent() {
  return (
    <div className="our-mission-content">
      <div className="content">
        <span className="sub-title">
          <img src={require("../../assets/img/star-icon.png")} alt="image" />
          Services
        </span>
        <h2>Design & Development</h2>
        <p>
          At <b>SpaceLakh</b>, we take pride in offering a holistic approach to
          website creation, seamlessly blending innovative design with robust
          development. Our mission is to transform your vision into a stunning
          and functional digital reality. Here's why our Design & Development
          services stand out.
        </p>
        <ul className="our-mission-list mb-0">
          <li>
            <i className="flaticon-tick"></i>Digital Marketing
          </li>
          <li>
            <i className="flaticon-tick"></i>Responsive Design
          </li>
          <li>
            <i className="flaticon-tick"></i>React Development
          </li>
          <li>
            <i className="flaticon-tick"></i>Apps Development
          </li>
          {/* <li>
            <i className="flaticon-tick"></i>Laravel Development
          </li> */}
          <li>
            <i className="flaticon-tick"></i>UX/UI Design
          </li>
          <li>
            <i className="flaticon-tick"></i>E-commerce Design
          </li>
          <li>
            <i className="flaticon-tick"></i>Web Design
          </li>
          <li>
            <i className="flaticon-tick"></i>Print Ready Design
          </li>
        </ul>
      </div>
    </div>
  );
}

export default OurMissionArea;
