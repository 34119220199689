import React from "react";
import BaseApp from "../../BaseComponent/baseApp";
import { HowItWorksSection, ProcessSection } from "../common/About-Us";
import { Link, useNavigate } from "react-router-dom";
import shape1 from "../../assets/img/shape/shape1.svg";
import HelmetPage from "./Helmet";

const WhySpaceLahk = () => {
  const navigate = useNavigate();
  return (
    <>
      <HelmetPage
        title={
          "Crafting Compelling Content - SpaceLakh's Guide to Digital Success"
        }
        content={
          "Elevate your content marketing game with SpaceLakh's expert tips. Learn to create engaging, shareable content that resonates with your target audience."
        }
      />
      <BaseApp>
        <div className="page-title-area">
          <div className="container">
            <div className="page-title-content">
              <h2>Why SpaceLakh</h2>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Why Choose</li>
              </ul>
            </div>
          </div>
          <div className="shape-img1">
            <img src={shape1} alt="image" />
          </div>
          <div className="shape-img2">
            <img
              src={require("../../assets/img/shape/shape2.png")}
              alt="image"
            />
          </div>
          <div className="shape-img3">
            <img
              src={require("../../assets/img/shape/shape3.png")}
              alt="image"
            />
          </div>
        </div>
        <HowItWorksSection />
        <ProcessSection />
      </BaseApp>
    </>
  );
};

export default WhySpaceLahk;
