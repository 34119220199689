import React from "react";
import BaseApp from "../../BaseComponent/baseApp";
import Shape from "../../assets/img/shape/shape1.svg";
import { Link } from "react-router-dom";

const LoginPage = () => {
  return (
    <BaseApp>
      <div>
        <div className="page-title-area">
          <div className="container">
            <div className="page-title-content">
              <h2>Profile Authentication</h2>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Login</li>
              </ul>
            </div>
          </div>
          <div className="shape-img1">
            <img src={Shape} alt="image" />
          </div>
          <div className="shape-img2">
            <img
              src={require("../../assets/img/shape/shape2.png")}
              alt="image"
            />
          </div>
          <div className="shape-img3">
            <img
              src={require("../../assets/img/shape/shape3.png")}
              alt="image"
            />
          </div>
        </div>
        <section className="profile-authentication-area ptb-100">
          <div className="container">
            <div
              className="row"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <div className="col-lg-8 col-md-12">
                <div className="login-form">
                  <h2>Login</h2>
                  <form>
                    <div className="form-group">
                      <label>Username or email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Username or email"
                      />
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                      />
                    </div>
                    <div className="row align-items-center">
                      <div className="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                        <div className="form-check m-0 p-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            value=""
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            Remember me
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                        <Link className="lost-your-password" to="/contact">
                          Lost your password?
                        </Link>
                      </div>
                    </div>
                    <button type="submit">Log In</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </BaseApp>
  );
};

export default LoginPage;
