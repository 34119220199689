import React from "react";
import Footer from "../componet/common/Footer";
import NavbarHeader from "../componet/common/NavBar";
import FloatingIcon from "../componet/Routes/ContactInfo";
import { Button } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export default function BaseApp({ children }) {
  const navigate = useNavigate();
  return (
    <div className="base-container">
      <NavbarHeader />
      <div className="base-content-container">
        <div
          style={{
            position: "fixed",
            bottom: 16,
            left: 16,
            zIndex: 1000,
          }}
        >
          <Button
            style={{ background: "#FF5D22", color: "white", fontWeight: "600" }}
            icon={<CalendarOutlined />}
            size="large"
            onClick={() => navigate("/book-a-meeting")}
          >
            Book Meeting
          </Button>
        </div>
        <FloatingIcon />
        {/* <Chatbot /> */}
        {children}
      </div>
      <Footer />
    </div>
  );
}
