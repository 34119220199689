import React from "react";
import ITServicesBanner from "../common/Banner";
import OurMissionArea from "../common/Mission";
import ServicesArea from "../common/Service";
import SolutionsArea from "../common/SolutionAt";
import PartnerArea from "../common/Partner";
import ProjectStartArea from "../common/ProjectArea";
import BaseApp from "../../BaseComponent/baseApp";
import HelmetPage from "../Routes/Helmet";

const Home = () => {
  return (
    <>
      <HelmetPage
        title={"Unleash the Power of Digital Marketing with SpaceLakh"}
        content={
          "Dive into the dynamic world of digital marketing. Explore strategies, trends, and tools to elevate your online presence with SpaceLakh."
        }
      />
      <BaseApp>
        <ITServicesBanner />
        <SolutionsArea />
        {/* <AboutArea /> */}
        <OurMissionArea />
        <ServicesArea />
        {/* <ScientistArea /> */}
        {/* <ProjectsArea /> */}
        {/* <PricingPlans /> */}
        {/* <Testimonials /> */}
        <PartnerArea />
        {/* <BlogArea /> */}
        <ProjectStartArea />
      </BaseApp>
    </>
  );
};

export default Home;
