import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div style={styles.container}>
      <div style={styles.topContainer}></div>
      <div style={styles.centerSection}>
        <img src="https://www.magnacast.com/images/404.gif" alt="404 Not Found" style={styles.image} />
        <h2 style={styles.heading}>Oops! Page Not Found</h2>
        <p style={styles.paragraph}>
          The page you are looking for might be in another galaxy. Let's get you back to Earth.
        </p>
        <Link to="/" style={styles.link}>
          Go Home
        </Link>
      </div>
      <div style={styles.footer}>
        <p style={styles.footerText}>© 2023 SpaceLakh. All rights reserved.</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: "relative",
    minHeight: "100vh",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  topContainer: {
    height: "50px",
    background: "#fff", 
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.9)", 
  },
  centerSection: {
    zIndex: 1,
    textAlign: "center",
    padding: "50px",
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    marginBottom: "50px",
  },
  image: {
    width: "100%",
    maxWidth: "400px",
    marginBottom: "20px",
  },
  heading: {
    color: "black",
    fontSize: "36px",
    marginBottom: "10px",
  },
  paragraph: {
    color: "black",
    fontSize: "18px",
    marginBottom: "30px",
  },
  link: {
    display: "inline-block",
    backgroundColor: "green",
    color: "#fff",
    padding: "15px 30px",
    borderRadius: "5px",
    textDecoration: "none",
    fontSize: "18px",
    fontWeight: "bold",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    transition: "background-color 0.3s ease-in-out",
  },
  footer: {
    textAlign: "center",
    padding: "20px",
    background: "#fff", 
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.5)",
  },
  footerText: {
    color: "#777",
    fontSize: "14px",
  },
};

export default NotFound;
