import React from "react";
import { Link } from "react-router-dom";

function SolutionsArea() {
  return (
    <section className="solutions-area pb-70">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={require("../../assets/img/star-icon.png")} alt="image" />
            Our Solutions
          </span>
          <h2>We Different From Others Should Choose Us</h2>
          <p>
            Our web design team goes beyond aesthetics; we create immersive
            digital experiences that blend innovative design concepts with
            user-friendly functionality. Your website won't just look great – it
            will perform seamlessly across devices, engaging visitors and
            converting leads effortlessly.
          </p>
        </div>
        <div className="row">
          <SolutionItem
            icon="flaticon-rocket"
            title="Code Security"
            description="Security is paramount in the digital age. Our web design process includes rigorous code security measures at every level. From secure coding practices to regular audits, we prioritize the protection of your website against potential vulnerabilities, ensuring a safe and secure online environment for your business and your users."
          />
          <SolutionItem
            icon="flaticon-laptop"
            title="Team Management"
            description="A well-coordinated team is essential for successful web design projects. Our experienced project managers ensure effective collaboration, efficient task allocation, and streamlined communication throughout the development process. With us, you'll have a dedicated team working cohesively to bring your vision to life."
          />
          <SolutionItem
            icon="flaticon-money"
            title="Access Controlled"
            description="Take control of your website with our robust access control mechanisms. We implement granular access controls to safeguard your data and ensure that only authorized personnel have access to sensitive areas. This not only enhances security but also empowers you to manage your website efficiently."
          />
        </div>
      </div>
    </section>
  );
}

function SolutionItem({ icon, title, description }) {
  return (
    <div className="col-lg-4 col-sm-6">
      <div className="single-solutions-box">
        <div className="icon">
          <i className={icon}></i>
        </div>
        <h3>
          <Link to="/">{title}</Link>
        </h3>
        <p
          style={{
            height: "300px",
            textOverflow: "ellipsis",
            whiteSpace: "normal",
          }}
        >
          {description}
        </p>
        <Link className="view-details-btn" to="/contact">
          View Details
        </Link>
      </div>
    </div>
  );
}

export default SolutionsArea;
