import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./componet/Routes/Home";

import ContactPage from "./componet/contactPage";
import SignUpPage from "./componet/Routes/Signup";
import LoginPage from "./componet/Routes/Login";
import AboutUs from "./componet/Routes/AboutUs";
import Services from "./componet/Routes/Services";
import YourComponent from "./componet/Test";
import WhySpaceLahk from "./componet/Routes/WhySpaceLahk";
import NotFound from "./componet/Routes/notFound";
import { BookMeeting } from "./componet/Routes/bookMeeting";
import ScrollToTop from "./BaseComponent/scroolOnTop";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/whychoose" element={<WhySpaceLahk />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/book-a-meeting" element={<BookMeeting />} />
          <Route path="/register" element={<SignUpPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/test" element={<YourComponent />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
