import React from "react";
import { Link } from "react-router-dom";

function ITServicesBanner() {
  return (
    <div className="it-services-banner">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="main-banner-content">
              <h1 className="animate__animated animate__fadeInLeft animate__delay-0.1s">
                Website Design & Digital Marketing Agency
              </h1>
              <p className="animate__animated animate__fadeInLeft animate__delay-0.1s">
                The One-Stop Digital Agency For Website Design, Website
                Development & Digital Marketing In India
              </p>
              <div
                style={{
                  gap: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="btn-box animate__animated animate__fadeInRight animate__delay-0.1s"
              >
                <Link className="default-btn" to="/services">
                  <i className="flaticon-right"></i>Our Services
                  <span></span>
                </Link>
                <Link className="default-btn" to="/contact">
                  <i className="flaticon-right"></i>Get Started
                  <span></span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="main-banner-image animate__animated animate__fadeInUp animate__delay-0.1s">
              <img
                src={require("../../assets/img/banner-img1.png")}
                alt="image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ITServicesBanner;
