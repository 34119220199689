import React from "react";
import BaseApp from "../../BaseComponent/baseApp";
import Shape from "../../assets/img/shape/shape1.svg";
import { Link } from "react-router-dom";

const SignUpPage = () => {
  return (
    <BaseApp>
      <div>
        <div className="page-title-area">
          <div className="container">
            <div className="page-title-content">
              <h2>Profile Authentication</h2>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>Register</li>
              </ul>
            </div>
          </div>
          <div className="shape-img1">
            <img src={Shape} alt="image" />
          </div>
          <div className="shape-img2">
            <img
              src={require("../../assets/img/shape/shape2.png")}
              alt="image"
            />
          </div>
          <div className="shape-img3">
            <img
              src={require("../../assets/img/shape/shape3.png")}
              alt="image"
            />
          </div>
        </div>
        <section className="profile-authentication-area ptb-100">
          <div className="container">
            <div
              className="row"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <div className="col-lg-8 col-md-12">
                <div className="register-form">
                  <h2>Register</h2>
                  <form>
                    <div className="form-group">
                      <label>Username</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Username or email"
                      />
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Username or email"
                      />
                    </div>
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Password"
                      />
                    </div>
                    <p className="description">
                      The password should be at least eight characters long. To
                      make it stronger, use upper and lower case letters,
                      numbers, and symbols like ! " ? $ % ^ & )
                    </p>
                    <button type="submit">Register</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </BaseApp>
  );
};

export default SignUpPage;
