import React from "react";
import { Link, useNavigate } from "react-router-dom";
import shape1 from "../../assets/img/shape/shape1.svg";

export const PageTitleArea = () => {
  const navigate = useNavigate();
  return (
    <div className="page-title-area">
      <div className="container">
        <div className="page-title-content">
          <h2>Services</h2>
          <ul>
            <li>
              {/* <Link className="nav-link" to="/">
                    Home
                  </Link> */}
              <a onClick={() => navigate("/")}>Home</a>
            </li>
            <li>Services</li>
          </ul>
        </div>
      </div>
      <div className="shape-img1">
        <img src={shape1} alt="image" />
      </div>
      <div className="shape-img2">
        <img src={require("../../assets/img/shape/shape2.png")} alt="image" />
      </div>
      <div className="shape-img3">
        <img src={require("../../assets/img/shape/shape3.png")} alt="image" />
      </div>
    </div>
  );
};

export const SolutionsArea = () => {
  return (
    <section className="solutions-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            {/* <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-rocket"></i>
              </div>
              <h3>
                <Link to="/contact">Startup Applications</Link>
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna adipiscing
                aliqua.
              </p>
              <Link className="view-details-btn" to="/contact">
                View Details
              </Link>
            </div> */}
          </div>
          {/* <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-laptop"></i>
              </div>
              <h3>
                <Link to="/">SaaS Solutions</Link>
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna adipiscing
                aliqua.
              </p>
              <Link className="view-details-btn" to="/contact">
                View Details
              </Link>
            </div>
          </div> */}
          {/* <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-money"></i>
              </div>
              <h3>
                <Link to="/">eCommerce Platforms</Link>
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna adipiscing
                aliqua.
              </p>
              <Link className="view-details-btn" to="/contact">
                View Details
              </Link>
            </div>
          </div> */}
          {/* <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-segmentation"></i>
              </div>
              <h3>
                <Link to="/">Research</Link>
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna adipiscing
                aliqua.
              </p>
              <Link className="view-details-btn" to="/contact">
                View Details
              </Link>
            </div>
          </div> */}
          {/* <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-analytics"></i>
              </div>
              <h3>
                <Link to="/">Analytics</Link>
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna adipiscing
                aliqua.
              </p>
              <Link className="view-details-btn" to="/contact">
                View Details
              </Link>
            </div>
          </div> */}
          {/* <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-solutions-box">
              <div className="icon">
                <i className="flaticon-settings"></i>
              </div>
              <h3>
                <Link to="/">Technology</Link>
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna adipiscing
                aliqua.
              </p>
              <Link className="view-details-btn" to="/contact">
                View Details
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export const ProjectStartArea = () => {
  return (
    <div className="project-start-area bg-color ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="project-start-image">
              <img src="../img/project-start1.png" alt="image" />
            </div>
          </div>
          {/* <div className="col-lg-6 col-md-12">
            <div className="project-start-content">
              <h2>We Like to Start Your Project With Us</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna.
              </p>
              <Link className="default-btn" to="/contact">
                <i className="flaticon-web"></i>Get Started<span></span>
              </Link>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className="vector-shape9">
        <img src="../img/shape/vector-shape9.png" alt="image" />
      </div>
      <div className="vector-shape10">
        <img src="../img/shape/vector-shape10.png" alt="image" />
      </div> */}
    </div>
  );
};

export const ServicesDetails = () => {
  return (
    <section className="services-details-area ptb-100">
      <div className="container">
        <div className="row">
          {/* <div className="col-lg-8 col-md-12">
            <div className="services-details-image">
              <img
                src={require("../../assets/img/services/services-details1.jpg")}
                alt="image"
              />
            </div>
            <div className="services-details-desc">
              <span className="sub-title">AI &amp; ML Development</span>
              <h3>About this Services</h3>
              <p>
                Improve Machine Learning algorithms by studying underfitting,
                overfitting, training, validation, n-fold cross-validation,
                testing, and how hyperparameters could improve performance.
                Perform linear and logistic regressions in Python.
              </p>
              <div className="row align-items-center">
                <div className="col-lg-6 col-md-6">
                  <div className="image">
                    <img
                      src={require("../../assets/img/projects/project2.jpg")}
                      alt="image"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="content">
                    <h3>Important Facts</h3>
                    <ul>
                      <li>The Field of Data Science</li>
                      <li>The Problem</li>
                      <li>The Solution</li>
                      <li>The Skills</li>
                      <li>Statistics</li>
                      <li>Mathematics</li>
                    </ul>
                  </div>
                </div>
              </div>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters, as opposed to using ‘Content
                here, content here’, making it look like readable English. Latin
                words, combined with a handful of model sentence structures, to
                generate Lorem Ipsum which looks reasonable.
              </p>
              <h3>Application Areas</h3>
              <div className="row">
                <div className="col-lg-4 col-sm-6 col-md-6">
                  <div className="single-industries-serve-box">
                    <div className="icon">
                      <i className="flaticon-factory"></i>
                    </div>
                    Manufacturing
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-md-6">
                  <div className="single-industries-serve-box">
                    <div className="icon">
                      <i className="flaticon-hospital"></i>
                    </div>
                    Healthcare
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-md-6">
                  <div className="single-industries-serve-box">
                    <div className="icon">
                      <i className="flaticon-tracking"></i>
                    </div>
                    Automobile
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-md-6">
                  <div className="single-industries-serve-box">
                    <div className="icon">
                      <i className="flaticon-investment"></i>
                    </div>
                    Banking
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-md-6">
                  <div className="single-industries-serve-box">
                    <div className="icon">
                      <i className="flaticon-house"></i>
                    </div>
                    Real Estate
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-md-6">
                  <div className="single-industries-serve-box">
                    <div className="icon">
                      <i className="flaticon-order"></i>
                    </div>
                    Logistics
                  </div>
                </div>
              </div>
              <h3>Technologies That We Use</h3>
              <ul className="technologies-features">
                <li>
                  <span>JavaScript</span>
                </li>
                <li>
                  <span>ReactJs</span>
                </li>
                <li>
                  <span>NextJs</span>
                </li>
                <li>
                  <span>WordPress</span>
                </li>
                <li>
                  <span>Python</span>
                </li>
                <li>
                  <span>Java</span>
                </li>
                <li>
                  <span>SQL</span>
                </li>
              </ul>
              <div className="charts-image">
                <img
                  src={require("../../assets/img/services/charts.jpg")}
                  alt="image"
                />
              </div>
            </div>
          </div> */}
          {/* <div className="col-lg-4 col-md-12">
            <div className="services-details-info">
              <ul className="services-list">
                <li>
                  <Link className="active" to="/">
                    AI &amp; ML Development
                  </Link>
                </li>
                <li>
                  <Link to="/">Data Analytics</Link>
                </li>
                <li>
                  <Link to="/">Data Science</Link>
                </li>
                <li>
                  <Link to="/">Artificial Intelligence</Link>
                </li>
                <li>
                  <Link to="/">Data Visualization</Link>
                </li>
              </ul>
              <div className="download-file">
                <h3>Brochures</h3>
                <ul>
                  <li>
                    <Link to="/">
                      PDF Download
                      <i className="bx bxs-file-pdf"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      Services Details.txt
                      <i className="bx bxs-file-txt"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="services-contact-info">
                <h3>Contact Info</h3>
                <ul>
                  <li>
                    <div className="icon">
                      <i className="bx bx-user-pin"></i>
                    </div>
                    <span>Phone:</span>
                    <Link to="tel:+91 8778161679" target="_blank">
                      +91 8778161679
                    </Link>
                  </li>
                  <li>
                    <div className="icon">
                      <i className="bx bx-map"></i>
                    </div>
                    <span>Location:</span>Salem TamilNadu -636010, India
                  </li>
                  <li>
                    <div className="icon">
                      <i className="bx bx-envelope"></i>
                    </div>
                    <span>Email:</span>
                    <Link to="https://mail.google.com/mail/u/0/?hl=en-GB&tf=cm&fs=1&to=contact@spacelakh.com">
                      <span
                        className="__cf_email__"
                        data-cfemail="167e737a7a7956627764783875797b"
                      >
                        contact@spacelakh.com
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export const MoreServices = () => {
  return (
    <section className="services-area pt-100 pb-70 bg-f1f8fb">
      {/* <div className="container">
        <div className="section-title">
          <h2>More Services You Might Like</h2>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-services-box">
              <div className="icon">
                <img
                  src={require("../../assets/img/services/service-icon1.png")}
                  alt="image"
                />
              </div>
              <h3>
                <Link to="/">Data Analytics</Link>
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna.
              </p>
              <Link className="read-more-btn" to="/">
                Read More
                <i className="flaticon-right"></i>
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-services-box">
              <div className="icon">
                <img
                  src={require("../../assets/img/services/service-icon2.png")}
                  alt="image"
                />
              </div>
              <h3>
                <Link to="/">AI & ML Development</Link>
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna.
              </p>
              <Link className="read-more-btn" to="/">
                Read More
                <i className="flaticon-right"></i>
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-services-box">
              <div className="icon">
                <img
                  src={require("../../assets/img/services/service-icon3.png")}
                  alt="image"
                />
              </div>
              <h3>
                <Link to="/">Data Science</Link>
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna.
              </p>
              <Link className="read-more-btn" to="/">
                Read More
                <i className="flaticon-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};
