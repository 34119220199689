import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import { Navbar, Nav } from "react-bootstrap";

const routeItems = ["home", "about-us",  "contact", "whychoose"];

function NavbarHeader() {
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsHeaderFixed(true);
      } else {
        setIsHeaderFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div id="navbar" className={isHeaderFixed ? "header-fixed" : "navbar-area"}>
      <div className="tarn-nav">
        <div className="container-fluid">
          <Navbar expand="lg" variant="light">
            <Link className="navbar-brand" to="/">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <img style={{ width: "50px" }} src={logo} alt="logo" />
                <h4
                  style={{
                    color: "#00AFEF",
                    fontFamily: "Inika",
                    fontWeight: "700",
                  }}
                >
                  SPACE<span style={{ color: "#FF5D22" }}>LAKH</span>
                </h4>
              </div>
            </Link>
            <Navbar.Toggle
              aria-controls="navbarSupportedContent"
              onClick={handleMobileMenuToggle}
            />
            <Navbar.Collapse id="navbarSupportedContent">
              <Nav className="mr-auto">
                {routeItems.map((item) => (
                  <Link
                    key={item}
                    className={`nav-link ${
                      location.pathname.includes(item) ? "active" : ""
                    }`}
                    to={`/${item}`}
                    style={{
                      color: location.pathname.includes(item) ? "#FF5D22" : "",
                      fontWeight: location.pathname.includes(item)
                        ? "bold"
                        : "bolder",
                    }}
                  >
                    {item === "home"
                      ? "Home"
                      : item.charAt(0).toUpperCase() + item.slice(1)}
                  </Link>
                ))}
              </Nav>
              <div className="others-option d-flex align-items-center">
                <div className="option-item">
                  <Link className="default-btn" to="/contact">
                    <i className="flaticon-right"></i> Get Started
                  </Link>
                </div>
              </div>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </div>
  );
}

function NavItem({ label, subItems }) {
  return (
    <li className="nav-item">
      <Link
        to={`/${label.toLowerCase().replace(/\s/g, "-")}`}
        className="nav-link"
      >
        {label}
        <i className="bx bx-chevron-down"></i>
      </Link>
      <ul className="dropdown-menu">
        {subItems.map((subItem, index) => (
          <li key={index} className="nav-item">
            <Link
              to={`/${subItem.toLowerCase().replace(/\s/g, "-")}`}
              className="nav-link"
            >
              {subItem}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
}
export default NavbarHeader;
