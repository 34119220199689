import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import image6 from "../../assets/shape/footer-map.png";
import "boxicons/css/boxicons.min.css";
import logo from "../../assets/logo.svg";
import { Link } from "react-router-dom";
// import { TiTick } from "react-icons/ti";

function Footer() {
  return (
    <footer className="footer-area bg-color">
      <Container>
        <Row>
          <Col lg={4} sm={6}>
            <div className="single-footer-widget">
              <Link
                to="/"
                className="logo"
                style={{
                  display: "flex",
                  gap: "20px",
                  flexDirection: "column",
                }}
              >
                <img src={logo} style={{ height: "60px" }} alt="logo" />
                <h4
                  style={{
                    color: "#00AFEF",
                    fontFamily: "Inika",
                    fontWeight: "700",
                  }}
                >
                  SPACE<span style={{ color: "#FF5D22" }}>LAKH</span>
                </h4>
              </Link>
              <p>
                "Elevate Your Vision, Code Your Success. Where Innovation Meets
                Excellence."
              </p>
              <ul className="social-link">
                <li>
                  <Link
                    className="d-block"
                    target="_blank"
                    to="https://www.facebook.com/profile.php?id=61555235480070&sfnsn=wiwspwa&mibextid=vk8aRt"
                  >
                    <i className="bx bxl-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    className="d-block"
                    target="_blank"
                    to="https://www.twitter.com/"
                  >
                    <i className="bx bxl-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    className="d-block"
                    target="_blank"
                    to="https://www.instagram.com/spacelakh?igsh=ODl0cGdjOTlveno5"
                  >
                    <i className="bx bxl-instagram"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    className="d-block"
                    target="_blank"
                    to="https://www.linkedin.com/"
                  >
                    <i className="bx bxl-linkedin"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={4} sm={6}>
            <div className="single-footer-widget pl-5">
              <h3>Explore</h3>
              <ul className="footer-links-list">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about-us">About</Link>
                </li>
                <li>
                  <Link to="/whychoose">Why SpaceLakh</Link>
                </li>
                {/* <li>
                  <Link to="/services">Services</Link>
                </li> */}
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                
              </ul>
            </div>
          </Col>
          <Col lg={4} sm={6}>
            <div className="single-footer-widget">
              <h3>Address</h3>
              <ul className="footer-contact-info">
                <li>
                  <i className="bx bx-map"></i>No.231 - 1, Sathya Garden,
                  Phase-2 Uthamasolapuram, Salem TamilNadu -636010, India
                </li>
                <li>
                  <i className="bx bx-phone-call"></i>
                  <Link to="tel:+44587154756">+91 8778161679</Link>
                </li>
                <li>
                  <i className="bx bx-envelope"></i>
                  <Link to="https://mail.google.com/mail/u/0/?hl=en-GB&tf=cm&fs=1&to=contact@spacelakh.com">
                    <span
                      className="__cf_email__"
                      data-cfemail="f098959c9c9fb08491829ede939f9d"
                    >
                      contact@spacelakh.com
                    </span>
                  </Link>
                </li>
                <li>
                  <i className="bx bxs-inbox"></i>
                  <Link to="tel:+91 8778161679">+91 8778161679 </Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <div className="footer-bottom-area">
          <Row className="align-items-center">
            <Col lg={8} md={8}>
              <p>
                Copyright ©2023<strong>SpaceLakh Info Technology</strong> All
                rights reserved
              </p>
            </Col>
            <Col lg={4} md={4}>
              <ul>
                <li>
                  <Link to="/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/">Terms &amp; Conditions</Link>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
      </Container>
      <div className="footer-map">
        <img src={image6} alt="image" />
      </div>
    </footer>
  );
}

export default Footer;
