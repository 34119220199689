import React from "react";
import BaseApp from "../../BaseComponent/baseApp";
import {
  MoreServices,
  PageTitleArea,
  ServicesDetails,
} from "../common/Services";
import HelmetPage from "./Helmet";

export default function Services() {
  return (
    <>
      <HelmetPage
        title={"Boost Your Brand on Instagram - Proven Marketing Strategies"}
        content={
          "Browse a wide range of products at SpaceLakh. From electronics to fashion, find the best deals on quality items. Shop conveniently and save with SpaceLakh. | Plan your next adventure with SpaceLakh Travel. Discover exotic destinations, book flights, and create memories that last a lifetime. Your journey starts here.| Access a wealth of educational resources at SpaceLakh Learning. From courses to tutorials, empower yourself with knowledge anytime, anywhere. Start your learning journey today. | Nourish your mind and body with SpaceLakh Wellness. Explore articles on fitness, nutrition, and mental health. Embrace a healthier lifestyle with SpaceLakh."
        }
      />
      <BaseApp>
        <PageTitleArea />
        {/* <SolutionsArea />
      <ProjectStartArea /> */}
        <ServicesDetails />
        <MoreServices />
      </BaseApp>
    </>
  );
}
